// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-list-js": () => import("../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-import-list-js": () => import("../src/pages/import-list.js" /* webpackChunkName: "component---src-pages-import-list-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

